import React, { useState, useEffect } from 'react';
import Hero from '../components/Hero';
import { BsBuilding } from 'react-icons/bs';
import { FaHandshake, FaBullseye, FaHandHoldingHeart } from 'react-icons/fa';
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion';
import '../styles/components.css';
import LogoMorph from '../components/LogoMorph';
import { Link } from 'react-router-dom';
import { 
  FaBuilding, 
  FaPencilRuler, 
  FaHardHat, 
  FaTools,
  FaSearchPlus,
  FaChartLine,
  FaCogs,
  FaClipboardCheck
} from 'react-icons/fa';
import { images } from '../constants/images';
import { projects } from '../constants/projects';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { clients } from '../constants/clients';

const Section = ({ children, className, background }) => {
  return (
    <motion.section
      className={`section-full ${className}`}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 1 }}
    >
      {background && (
        <>
          <div className="overlay"></div>
          <motion.img
            src={background}
            className="parallax-bg"
            initial={{ scale: 1.2 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1 }}
          />
        </>
      )}
      <div className="container section-content">
        {children}
      </div>
    </motion.section>
  );
};

const philosophyContent = [
  {
    icon: FaPencilRuler,
    title: "Perfectly Design",
    text: "We believe in creating architectural designs that perfectly balance aesthetics, functionality, and sustainability. Every detail is carefully considered to create spaces that inspire and endure."
  },
  {
    icon: FaClipboardCheck,
    title: "Carefully Planned",
    text: "Our meticulous planning process ensures that every project is executed with precision. We consider all aspects from urban context to environmental impact, creating comprehensive solutions."
  },
  {
    icon: FaCogs,
    title: "Smartly Execute",
    text: "Through innovative construction methods and smart project management, we transform architectural visions into reality. Our execution combines technical expertise with practical efficiency."
  }
];

const PhilosophySection = ({ icon: Icon, title, text }) => {
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], [0, 100]);

  return (
    <motion.div 
      className="philosophy-card"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      style={{ y }}
    >
      <div className="philosophy-icon">
        <Icon size={40} />
      </div>
      <h3>{title}</h3>
      <p>{text}</p>
    </motion.div>
  );
};

const ProjectCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedProjects, setSelectedProjects] = useState([]);

  useEffect(() => {
    // Filter projects with valid images and randomly select from all types
    const validProjects = projects.filter(project => 
      Array.isArray(project.image) && 
      project.image.length > 0
    );
    
    // Shuffle the array to get random projects
    const shuffledProjects = [...validProjects].sort(() => Math.random() - 0.5);
    
    // Take first 5 projects or all if less than 5
    const randomProjects = shuffledProjects.slice(0, Math.min(5, shuffledProjects.length));
    setSelectedProjects(randomProjects);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % selectedProjects.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev - 1 + selectedProjects.length) % selectedProjects.length);
  };

  if (selectedProjects.length === 0) return null;

  return (
    <section id="featured-projects">
      <div className="container">
        <h2 id="featured-title">Featured Projects</h2>
        <div id="carousel-container">
          <button className="carousel-button prev" onClick={prevSlide}>
            <FaArrowLeft />
          </button>
          <motion.div 
            id="carousel-content"
            key={currentIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div id="carousel-image">
              <img 
                src={selectedProjects[currentIndex]?.image[0]} 
                alt={selectedProjects[currentIndex]?.name}
              />
            </div>
            <div id="carousel-info">
              <h3>{selectedProjects[currentIndex]?.name}</h3>
              <div className="project-details">
                <span className="project-type">{selectedProjects[currentIndex]?.type}</span>
                <span className="project-year">{selectedProjects[currentIndex]?.year}</span>
              </div>
            </div>
          </motion.div>
          <button className="carousel-button next" onClick={nextSlide}>
            <FaArrowRight />
          </button>
        </div>
        <div className="carousel-dots">
          {selectedProjects.map((_, index) => (
            <span 
              key={index} 
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const ClientsSection = () => {

  return (
    <section className="clients-section">
      <div className="container">
        <h2 className="section-title">Our Clients</h2>
        <div className="clients-grid">
          {clients.map((client, index) => (
            <motion.div 
              key={index}
              className="client-card"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "100px" }}
              transition={{ 
                duration: 0.3,
                delay: index * 0.05
              }}
            >
              <img src={client.logo} alt={client.name} />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const Main = ({ onAnimationComplete }) => {
  const [showContent, setShowContent] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  
  useEffect(() => {
    const hasVisited = sessionStorage.getItem('hasVisited');
    setIsFirstVisit(!hasVisited);
  }, []);

  const handleAnimationComplete = () => {
    setShowContent(true);
    if (onAnimationComplete) onAnimationComplete();
    sessionStorage.setItem('hasVisited', 'true');
  };

  const philosophyItems = [
    {
      title: "Vision",
      description: "To be Vietnam's leading architectural design and construction solutions provider, creating sustainable and innovative spaces that enhance lives.",
      image: images.vision
    },
    {
      title: "Experience",
      description: "With years of expertise in architectural design and construction, we deliver excellence through proven methodologies and skilled professionals.",
      image: images.experience
    },
    {
      title: "Innovation",
      description: "Embracing cutting-edge technology and creative solutions to push the boundaries of architectural design and construction efficiency.",
      image: images.innovation
    },
    {
      title: "Cooperation",
      description: "Building strong partnerships with clients, contractors, and stakeholders to ensure project success through collaborative excellence.",
      image: images.cooperation
    }
  ];

  const services = [
    {
      icon: <FaPencilRuler />,
      title: "Architectural Design",
      description: "Comprehensive architectural design services for various building types, from concept to detailed drawings."
    },
    {
      icon: <FaBuilding />,
      title: "Construction Design",
      description: "Full construction design documentation including structural, mechanical, and electrical systems."
    },
    {
      icon: <FaHardHat />,
      title: "Construction Management",
      description: "Professional oversight of construction projects ensuring quality, timeline, and budget compliance."
    },
    {
      icon: <FaTools />,
      title: "Technical Infrastructure",
      description: "Design and implementation of essential building infrastructure and utility systems."
    },
    {
      icon: <FaSearchPlus />,
      title: "Project Assessment",
      description: "Thorough evaluation and feasibility studies for construction projects and developments."
    },
    {
      icon: <FaHandshake />,
      title: "Construction Consulting",
      description: "Expert advice on construction methods, materials, and project optimization strategies."
    },
    {
      icon: <FaChartLine />,
      title: "Project Planning",
      description: "Strategic planning and development of construction projects from inception to completion."
    },
    {
      icon: <FaCogs />,
      title: "Technical Solutions",
      description: "Innovative technical solutions for complex architectural and construction challenges."
    }
  ];

  return (
    <main>
      {/* Preload Hero component with opacity 0 */}
      <motion.div
        style={{ 
          position: showContent ? 'relative' : 'absolute',
          width: '100%',
          opacity: showContent ? 1 : 0,
          pointerEvents: showContent ? 'auto' : 'none'
        }}
      >
        <Hero />
      </motion.div>

      <AnimatePresence mode="wait">
        {!showContent ? (
          <LogoMorph 
            key="logo-morph"
            onAnimationComplete={handleAnimationComplete} 
            isFirstVisit={isFirstVisit}
          />
        ) : (
          <motion.div
            key="main-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <section className="philosophy-section">
              <div className="container">
                <div className="philosophy-grid">
                  {philosophyContent.map((item, index) => (
                    <PhilosophySection 
                      key={index}
                      icon={item.icon}
                      title={item.title}
                      text={item.text}
                    />
                  ))}
                </div>
              </div>
            </section>

            <ProjectCarousel />

            <section className="what-we-do-section">
              <div className="container">
                <h2 className="section-title text-center">What We Do</h2>
                <div className="services-grid">
                  {services.map((service, index) => (
                    <motion.div
                      key={index}
                      className="service-card"
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.1 }}
                    >
                      <div className="service-icon">
                        {service.icon}
                      </div>
                      <h3>{service.title}</h3>
                      <p>{service.description}</p>
                    </motion.div>
                  ))}
                </div>
              </div>
            </section>

            <ClientsSection />
          </motion.div>
        )}
      </AnimatePresence>
    </main>
  );
};

export default Main;