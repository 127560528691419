import React, { useState, useEffect, useCallback } from 'react';
import { projects } from '../constants/projects';
import ProjectCard from '../components/ProjectCard';
import { FaFilter } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import ProjectPopup from '../components/ProjectPopup';
import '../styles/components.css';
import '../styles/buildingTypes.css';

const buildingTypes = [
  { id: 'all', label: 'All' },
  { id: 'Public Building', label: 'Public Building' },
  { id: 'Residential House', label: 'Residential House' },
  { id: 'Master Planning and Landscaping', label: 'Master Planning and Landscaping' },
  { id: 'Interior Design', label: 'Interior Design' },
  { id: 'Out sourcing', label: 'Out Sourcing' }
];

const Projects = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [searchYear, setSearchYear] = useState('');
  const [activeType, setActiveType] = useState('all');
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    const filtered = projects.filter(project => {
      const nameMatch = project.name.toLowerCase().includes(searchName.toLowerCase());
      const yearMatch = searchYear === '' || project.year.includes(searchYear);
      const typeMatch = activeType === 'all' || project.type === activeType;
      return nameMatch && yearMatch && typeMatch;
    });
    setFilteredProjects(filtered);
  }, [searchName, searchYear, activeType]);

  return (
    <div className="container py-5 projects-container">
      <div className="project-header">
        <h1>Our Projects</h1>
        <nav className="building-types-nav">
          {buildingTypes.map(type => (
            <a
              key={type.id}
              href={`#${type.id}`}
              className={`building-type-link ${activeType === type.id ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                setActiveType(type.id);
              }}
            >
              {type.label}
            </a>
          ))}
        </nav>
      </div>

      <motion.div 
        className="row row-cols-1 row-cols-md-3 g-4"
        layout
      >
        {filteredProjects.map(project => (
          <motion.div 
            key={project.id} 
            className="col"
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <ProjectCard 
              project={project} 
              onProjectClick={setSelectedProject}
            />
          </motion.div>
        ))}
      </motion.div>

      <AnimatePresence>
        {selectedProject && (
          <ProjectPopup 
            project={selectedProject} 
            onClose={() => setSelectedProject(null)} 
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Projects;