import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import '../styles/components.css';

const LogoMorph = ({ onAnimationComplete, isFirstVisit }) => {
  const [showFull, setShowFull] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setTimeout(() => setShowFull(true), isFirstVisit ? 500 : 200);
    }, isFirstVisit ? 1500 : 500);

    return () => clearTimeout(timer);
  }, [isFirstVisit]);

  const containerVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { 
      opacity: 0,
      transition: { 
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <motion.div 
      className="logo-morph-container"
      variants={containerVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="logo-morph-content">
        <AnimatePresence>
          {loading && (
            <motion.div
              className="loading-dots"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                fontSize: '4rem',
                letterSpacing: '0.5rem',
                lineHeight: '1',
                marginBottom: '2rem'
              }}
            >
              <motion.span
                animate={{
                  opacity: [0, 1, 0],
                  y: [0, -10, 0]
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  delay: 0
                }}
              >
                .
              </motion.span>
              <motion.span
                animate={{
                  opacity: [0, 1, 0],
                  y: [0, -10, 0]
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  delay: 0.2
                }}
              >
                .
              </motion.span>
              <motion.span
                animate={{
                  opacity: [0, 1, 0],
                  y: [0, -10, 0]
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  delay: 0.4
                }}
              >
                .
              </motion.span>
            </motion.div>
          )}
        </AnimatePresence>

        <div className="logo-text-container">
          <div className="logo-group">
            <motion.span
              className="letter"
              initial={{ scale: 1 }}
              animate={{ 
                x: showFull ? 0 : 0,
              }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              A
            </motion.span>
            <motion.span
              className="expanded-text"
              initial={{ width: 0, opacity: 0 }}
              animate={{ 
                width: showFull ? "auto" : 0,
                opacity: showFull ? 1 : 0
              }}
              transition={{ duration: 0.6, delay: 0.1 }}
            >
              rchitecture and high
            </motion.span>
          </div>

          <div className="logo-group">
            <motion.span
              className="letter"
              initial={{ scale: 1 }}
              animate={{ 
                x: showFull ? 0 : 0,
              }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              B
            </motion.span>
            <motion.span
              className="expanded-text"
              initial={{ width: 0, opacity: 0 }}
              animate={{ 
                width: showFull ? "auto" : 0,
                opacity: showFull ? 1 : 0
              }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              uilding
            </motion.span>
          </div>

          <div className="logo-group">
            <motion.span
              className="letter"
              initial={{ scale: 1 }}
              animate={{ 
                x: showFull ? 0 : 0,
              }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              S
            </motion.span>
            <motion.span
              className="expanded-text"
              initial={{ width: 0, opacity: 0 }}
              animate={{ 
                width: showFull ? "auto" : 0,
                opacity: showFull ? 1 : 0
              }}
              transition={{ 
                duration: isFirstVisit ? 0.6 : 0.3,
                delay: isFirstVisit ? 0.3 : 0.1 
              }}
              onAnimationComplete={() => {
                if (showFull) {
                  setTimeout(() => {
                    onAnimationComplete();
                  }, isFirstVisit ? 800 : 300);
                }
              }}
            >
              olutions
            </motion.span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default LogoMorph; 