import React, { createContext, useState, useContext } from 'react';

const LanguageContext = createContext();

export const translations = {
  en: {
    about: {
      title: "About Us",
      subtitle: "Building Dreams, Creating Futures",
      whoWeAre: {
        title: "Who We Are",
        content: "Architecture and high Building Solutions Corporation (ABS) is one of prestigious companies and gradually build up reputation in architecture and construction in Vietnam. Being established in 2007 by experienced, competent and enthusiastic members, ABS has been trying his best continuously to bring clients consulting services at the highest quality and the most intelligent and effective solutions. We have created our own path which is oriented to the modern architectural trend, appropriate with regional economic conditions, technologies as well as climate conditions."
      },
      commitment: {
        title: "Our Commitment",
        content: "Specializing in design consultant, ABS always commits to implement projects successfully and take sustainable values to each projects. We understand and believe that the value of the prestige creation through the service quality and satisfaction to the clients' increasing strict requirements will be the solid foundation for our sustainable development.",
        content2: "ABS has a modern way of thinking and a systematic working style. We are ready to share knowledge and develop working skills constantly and create new things continuously. We are eager to co-operate with domestic and foreign businesses for jointly development."
      },
      stats: {
        experience: "Years Experience",
        projects: "Projects Completed",
        clients: "Happy Clients",
        success: "Success Rate"
      },
      companyInfo: {
        title: "Company Details",
        fullName: "Full Name",
        shortName: "Short Name",
        address: "Address",
        capital: "Regulation Capital",
        taxCode: "Tax Code"
      },
      contactInfo: {
        title: "Contact Information",
        tel: "Tel",
        fax: "Fax",
        email: "Email",
        website: "Website",
        bankAccount: "Bank Account"
      },
      cooperation: {
        title: "International Cooperation",
        content: "The Vietnam construction market holds immense potential, with numerous domestic and foreign investors seeking to invest in land and buildings. As projects become more diverse and complex, requiring accurate technical solutions, flexible material applications, and sound financial planning, ABS recognizes the importance of collaboration.",
        content2: "We actively pursue partnerships with both domestic organizations and international consultants to achieve project success and deliver exceptional results to our clients."
      },
      journey: {
        title: "Our Journey",
        foundation: {
          title: "Foundation",
          text: "Company established with a vision to transform architectural landscape."
        },
        growth: {
          title: "Growth",
          text: "Expanded operations and completed first major project."
        },
        innovation: {
          title: "Innovation",
          text: "Introduced sustainable design practices and modern technologies."
        },
        excellence: {
          title: "Excellence",
          text: "Recognized as industry leader with multiple awards."
        }
      },
      team: {
        title: "Our Team",
        roles: {
          principalArchitect: "Principal Architect",
          designDirector: "Design Director",
          seniorArchitect: "Senior Architect",
          projectManager: "Project Manager",
          technicalDirector: "Technical Director",
          constructionManager: "Construction Manager",
          seniorEngineer: "Senior Engineer",
          projectCoordinator: "Project Coordinator",
          designEngineer: "Design Engineer",
          interiorDesigner: "Interior Designer",
          structuralEngineer: "Structural Engineer",
          projectEngineer: "Project Engineer",
          qualityManager: "Quality Manager",
          constructionSupervisor: "Construction Supervisor",
          designCoordinator: "Design Coordinator"
        }
      }
    }
  },
  vi: {
    about: {
      title: "Về Chúng Tôi",
      subtitle: "Xây Dựng Ước Mơ, Kiến Tạo Tương Lai",
      whoWeAre: {
        title: "Chúng Tôi Là Ai",
        content: "Công ty Cổ phần Kiến trúc và Giải pháp Công trình cao tầng (ABS) là một trong những công ty uy tín và đang dần xây dựng danh tiếng trong lĩnh vực kiến trúc và xây dựng tại Việt Nam. Được thành lập vào năm 2007 bởi các thành viên giàu kinh nghiệm, năng lực và nhiệt huyết, ABS luôn nỗ lực không ngừng để mang đến cho khách hàng dịch vụ tư vấn chất lượng cao nhất với những giải pháp thông minh và hiệu quả nhất. Chúng tôi đã tạo ra con đường riêng hướng đến xu hướng kiến trúc hiện đại, phù hợp với điều kiện kinh tế khu vực, công nghệ cũng như điều kiện khí hậu."
      },
      commitment: {
        title: "Cam Kết Của Chúng Tôi",
        content: "Chuyên về tư vấn thiết kế, ABS luôn cam kết thực hiện thành công các dự án và mang lại giá trị bền vững cho từng công trình. Chúng tôi hiểu và tin rằng giá trị của việc tạo dựng uy tín thông qua chất lượng dịch vụ và sự hài lòng đối với các yêu cầu ngày càng khắt khe của khách hàng sẽ là nền tảng vững chắc cho sự phát triển bền vững của chúng tôi.",
        content2: "ABS có cách tư duy hiện đại và phong cách làm việc có hệ thống. Chúng tôi sẵn sàng chia sẻ kiến thức và không ngừng phát triển kỹ năng làm việc, liên tục sáng tạo những điều mới. Chúng tôi mong muốn hợp tác với các doanh nghiệp trong và ngoài nước để cùng phát triển."
      },
      stats: {
        experience: "Năm Kinh Nghiệm",
        projects: "Dự Án Hoàn Thành",
        clients: "Khách Hàng Hài Lòng",
        success: "Tỷ Lệ Thành Công"
      },
      companyInfo: {
        title: "Thông Tin Công Ty",
        fullName: "Tên Đầy Đủ",
        shortName: "Tên Viết Tắt",
        address: "Địa Chỉ",
        capital: "Vốn Điều Lệ",
        taxCode: "Mã Số Thuế"
      },
      contactInfo: {
        title: "Thông Tin Liên Hệ",
        tel: "Điện Thoại",
        fax: "Fax",
        email: "Email",
        website: "Website",
        bankAccount: "Tài Khoản Ngân Hàng"
      },
      cooperation: {
        title: "Hợp Tác Quốc Tế",
        content: "Thị trường xây dựng Việt Nam có tiềm năng to lớn, với nhiều nhà đầu tư trong và ngoài nước muốn đầu tư vào đất đai và công trình. Khi các dự án ngày càng đa dạng và phức tạp, đòi hỏi giải pháp kỹ thuật chính xác, ứng dụng vật liệu linh hoạt và kế hoạch tài chính hợp lý, ABS nhận thức được tầm quan trọng của sự hợp tác.",
        content2: "Chúng tôi tích cực theo đuổi quan hệ đối tác với các tổ chức trong nước và tư vấn quốc tế để đạt được thành công trong dự án và mang lại kết quả xuất sắc cho khách hàng."
      },
      journey: {
        title: "Hành Trình Phát Triển",
        foundation: {
          title: "Thành Lập",
          text: "Công ty được thành lập với tầm nhìn thay đổi diện mạo kiến trúc."
        },
        growth: {
          title: "Phát Triển",
          text: "Mở rộng hoạt động và hoàn thành dự án lớn đầu tiên."
        },
        innovation: {
          title: "Đổi Mới",
          text: "Áp dụng thực hành thiết kế bền vững và công nghệ hiện đại."
        },
        excellence: {
          title: "Xuất Sắc",
          text: "Được công nhận là đơn vị dẫn đầu ngành với nhiều giải thưởng."
        }
      },
      team: {
        title: "Đội Ngũ Của Chúng Tôi",
        roles: {
          principalArchitect: "Kiến Trúc Sư Trưởng",
          designDirector: "Giám Đốc Thiết Kế",
          seniorArchitect: "Kiến Trúc Sư Cao Cấp",
          projectManager: "Quản Lý Dự Án",
          technicalDirector: "Giám Đốc Kỹ Thuật",
          constructionManager: "Quản Lý Xây Dựng",
          seniorEngineer: "Kỹ Sư Cao Cấp",
          projectCoordinator: "Điều Phối Dự Án",
          designEngineer: "Kỹ Sư Thiết Kế",
          interiorDesigner: "Thiết Kế Nội Thất",
          structuralEngineer: "Kỹ Sư Kết Cấu",
          projectEngineer: "Kỹ Sư Dự Án",
          qualityManager: "Quản Lý Chất Lượng",
          constructionSupervisor: "Giám Sát Xây Dựng",
          designCoordinator: "Điều Phối Thiết Kế"
        }
      }
    }
  }
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  const toggleLanguage = () => {
    setLanguage(prev => prev === 'en' ? 'vi' : 'en');
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage, translations }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}; 