import React from 'react';
import { motion } from 'framer-motion';
import { Link, NavLink } from 'react-router-dom';
import { images } from '../constants/images';
import '../styles/components.css';

const Hero = () => {
  return (
    <motion.div
      className="hero"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <img src={images.hero} alt="Hero Image" className="hero-image" />
      <div className="hero-content">
        <div className="hero-title">
          <div className="title-group first">
            <span className="hero-letter">A</span>
            <span className="hero-text">rchitecture and high</span>
          </div>
          <div className="title-group second">
            <span className="hero-letter">B</span>
            <span className="hero-text">uilding</span>
          </div>
          <div className="title-group third">
            <span className="hero-letter">S</span>
            <span className="hero-text">olutions</span>
          </div>
        </div>
        <p className="hero-tagline">We are the architects for professional clients.</p>
        <div className="hero-button-container">
          <Link to="/featured">
            <motion.button 
              className="btn btn-primary"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              See Our Work
            </motion.button>
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default Hero;