import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa';
import '../styles/components.css';
import { preloadImages } from '../utils/imageLoader';

const FullScreenImage = ({ src, alt, onClose }) => {
  return (
    <motion.div 
      className="fullscreen-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.img
        src={src}
        alt={alt}
        className="fullscreen-image"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
        onClick={(e) => e.stopPropagation()}
      />
      <button className="fullscreen-close" onClick={onClose}>
        <FaTimes />
      </button>
    </motion.div>
  );
};

const ProjectCard = ({ project, onProjectClick }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const images = Array.isArray(project.image) ? project.image : [project.image];

  // Preload current image when it changes
  useEffect(() => {
    setIsImageLoaded(false);
    const img = new Image();
    img.src = images[currentImageIndex];
    img.onload = () => setIsImageLoaded(true);
  }, [currentImageIndex, images]);

  const handleImageClick = (e) => {
    e.stopPropagation();
    setFullscreenImage(images[currentImageIndex]);
  };

  const nextImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const prevImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <>
      <motion.div
        className="project-card"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={() => onProjectClick(project)}
      >
        <div className="project-image-container">
          {images.length > 1 && (
            <>
              <button 
                className="image-nav-button prev" 
                onClick={prevImage}
                aria-label="Previous image"
              >
                <FaChevronLeft />
              </button>
              <button 
                className="image-nav-button next" 
                onClick={nextImage}
                aria-label="Next image"
              >
                <FaChevronRight />
              </button>
              <div className="image-indicators">
                {images.map((_, index) => (
                  <div
                    key={index}
                    className={`indicator ${index === currentImageIndex ? 'active' : ''}`}
                  />
                ))}
              </div>
            </>
          )}
          {!isImageLoaded && (
            <div className="image-placeholder">
              <div className="loading-spinner"></div>
            </div>
          )}
          <motion.img
            key={currentImageIndex}
            src={images[currentImageIndex]}
            alt={`${project.name} - Image ${currentImageIndex + 1}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: isImageLoaded ? 1 : 0 }}
            transition={{ duration: 0.3 }}
            className="project-image"
            onClick={handleImageClick}
          />
        </div>
        
        <div className="card-body">
          <h5 className="card-title">{project.name}</h5>
          <p className="card-text">{project.description}</p>
          <div className="card-meta">
            <p className="text-muted">Year: {project.year}</p>
            <p className="text-muted">Type: {project.type}</p>
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        {fullscreenImage && (
          <FullScreenImage
            src={fullscreenImage}
            alt={project.name}
            onClose={() => setFullscreenImage(null)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default ProjectCard;