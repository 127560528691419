// Project image mappings from local PROJECT folder

const baseProjectPath = '/PROJECT';

const projectImages = {
    LANDSCAPE: {
        NHA_MAY_THIET_BI_Y_TE_BBRAUN: [
            `${baseProjectPath}/LANDSCAPE/NHA_MAY_THIET_BI_Y_TE_BBRAUN/NHA_MAY_THIET_BI_Y_TE_BBRAUN1.jpg`,
            `${baseProjectPath}/LANDSCAPE/NHA_MAY_THIET_BI_Y_TE_BBRAUN/NHA_MAY_THIET_BI_Y_TE_BBRAUN2.jpg`
        ],
        SONG_DAY_PHU_LY: [
            `${baseProjectPath}/LANDSCAPE/SONG_DAY_-_PHU_LY/SONG_DAY_-_PHU_LY1.jpg`,
            `${baseProjectPath}/LANDSCAPE/SONG_DAY_-_PHU_LY/SONG_DAY_-_PHU_LY2.jpg`
        ]
    },
    LOW_RISE_HOUSING: {
        BT_SINH_THAI_NHU_QUYNH: [
            `${baseProjectPath}/LOW-RISE_HOUSING/BT_SINH_THAI_NHU_QUYNH/BT_SINH_THAI_NHU_QUYNH1.jpg`,
            `${baseProjectPath}/LOW-RISE_HOUSING/BT_SINH_THAI_NHU_QUYNH/BT_SINH_THAI_NHU_QUYNH2.jpg`
        ],
        KHU_NHA_THAP_TANG_HAPULICO: [
            `${baseProjectPath}/LOW-RISE_HOUSING/KHU_NHA_THAP_TANG_-_HAPULICO_COMPLEX/KHU_NHA_THAP_TANG_-_HAPULICO_COMPLEX1.jpg`,
            `${baseProjectPath}/LOW-RISE_HOUSING/KHU_NHA_THAP_TANG_-_HAPULICO_COMPLEX/KHU_NHA_THAP_TANG_-_HAPULICO_COMPLEX2.jpg`
        ],
        NHA_O_THAP_TANG_HANOI_GARDEN: [
            `${baseProjectPath}/LOW-RISE_HOUSING/NHA_O_THAP_TANG_-_HANOI_GARDEN_CITY/NHA_O_THAP_TANG_-_HANOI_GARDEN_CITY1.jpg`,
            `${baseProjectPath}/LOW-RISE_HOUSING/NHA_O_THAP_TANG_-_HANOI_GARDEN_CITY/NHA_O_THAP_TANG_-_HANOI_GARDEN_CITY2.jpg`
        ]
    },
    PRIVATE_HOUSING: {
        B_HOUSE: [
            `${baseProjectPath}/PRIVATE_HOUSING/B_HOUSE/B_HOUSE1.jpg`,
            `${baseProjectPath}/PRIVATE_HOUSING/B_HOUSE/B_HOUSE2.jpg`
        ],
        C_HOUSE: [
            `${baseProjectPath}/PRIVATE_HOUSING/C_HOUSE/C_HOUSE1.jpg`,
            `${baseProjectPath}/PRIVATE_HOUSING/C_HOUSE/C_HOUSE2.jpg`
        ],
        D_HOUSE: [
            `${baseProjectPath}/PRIVATE_HOUSING/D_HOUSE/D_HOUSE1.jpg`,
            `${baseProjectPath}/PRIVATE_HOUSING/D_HOUSE/D_HOUSE2.jpg`
        ],
        L_HOUSE: [
            `${baseProjectPath}/PRIVATE_HOUSING/L_HOUSE/L_HOUSE1.jpg`,
            `${baseProjectPath}/PRIVATE_HOUSING/L_HOUSE/L_HOUSE2.jpg`
        ],
        T_HOUSE: [
            `${baseProjectPath}/PRIVATE_HOUSING/T_HOUSE/T_HOUSE1.jpg`,
            `${baseProjectPath}/PRIVATE_HOUSING/T_HOUSE/T_HOUSE2.jpg`
        ]
    },
    SCHOOL: {
        GIANG_DUONG_TRUNG_TAM_HVKHQS: [
            `${baseProjectPath}/SCHOOL/GIANG_DUONG_TRUNG_TAM_-_HVKHQS/GIANG_DUONG_TRUNG_TAM_-_HVKHQS1.jpg`,
            `${baseProjectPath}/SCHOOL/GIANG_DUONG_TRUNG_TAM_-_HVKHQS/GIANG_DUONG_TRUNG_TAM_-_HVKHQS2.jpg`
        ],
        KHU_HOC_VIEN_QUOC_TE_HVKHQS: [
            `${baseProjectPath}/SCHOOL/KHU_HOC_VIEN_QUOC_TE_-_HVKHQS/KHU_HOC_VIEN_QUOC_TE_-_HVKHQS1.jpg`,
            `${baseProjectPath}/SCHOOL/KHU_HOC_VIEN_QUOC_TE_-_HVKHQS/KHU_HOC_VIEN_QUOC_TE_-_HVKHQS2.jpg`
        ],
        NHA_THE_THAO_DA_NANG: [
            `${baseProjectPath}/SCHOOL/NHA_THE_THAO_DA_NANG/NHA_THE_THAO_DA_NANG1.jpg`,
            `${baseProjectPath}/SCHOOL/NHA_THE_THAO_DA_NANG/NHA_THE_THAO_DA_NANG2.jpg`
        ],
        UNIWORLD_INTERNATIONAL_SCHOOL: [
            `${baseProjectPath}/SCHOOL/UNIWORLD_INTERNATIONAL_SCHOOL/UNIWORLD_INTERNATIONAL_SCHOOL1.jpg`,
            `${baseProjectPath}/SCHOOL/UNIWORLD_INTERNATIONAL_SCHOOL/UNIWORLD_INTERNATIONAL_SCHOOL2.jpg`
        ]
    },
    APARTMENT: {
        CHUNG_CU_CT1A_CT1B_ME_TRI: [
            `${baseProjectPath}/APARTMENT/CHUNG_CU_CT1A-CT1B_ME_TRI/CHUNG_CU_CT1A-CT1B_ME_TRI1.jpg`,
            `${baseProjectPath}/APARTMENT/CHUNG_CU_CT1A-CT1B_ME_TRI/CHUNG_CU_CT1A-CT1B_ME_TRI2.jpg`,
            `${baseProjectPath}/APARTMENT/CHUNG_CU_CT1A-CT1B_ME_TRI/CHUNG_CU_CT1A-CT1B_ME_TRI3.jpg`
        ],
        CHUNG_CU_CT5_AVENUE_PARK: [
            `${baseProjectPath}/APARTMENT/CHUNG_CU_CT5_AVENUE_PARK_-_HANOI_GARDEN_CITY/CHUNG_CU_CT5_AVENUE_PARK_-_HANOI_GARDEN_CITY1.jpg`,
            `${baseProjectPath}/APARTMENT/CHUNG_CU_CT5_AVENUE_PARK_-_HANOI_GARDEN_CITY/CHUNG_CU_CT5_AVENUE_PARK_-_HANOI_GARDEN_CITY2.jpg`
        ],
        ITASCO_TOWER: [
            `${baseProjectPath}/APARTMENT/ITASCO_TOWER/ITASCO_TOWER1.jpg`,
            `${baseProjectPath}/APARTMENT/ITASCO_TOWER/ITASCO_TOWER2.jpg`
        ],
        NHA_CONG_VU_HVKH_QUAN_SU: [
            `${baseProjectPath}/APARTMENT/NHA_CONG_VU_-_HVKH_QUAN_SU/NHA_CONG_VU_-_HVKH_QUAN_SU1.jpg`
        ],
        NHA_HE_135: [
            `${baseProjectPath}/APARTMENT/NHA_HE_1,3,5/NHA_HE_1,3,5135_ok.jpg`,
            `${baseProjectPath}/APARTMENT/NHA_HE_1,3,5/NHA_HE_1,3,5135_ok2.jpg`,
            `${baseProjectPath}/APARTMENT/NHA_HE_1,3,5/NHA_HE_1,3,5135_ok3.jpg`
        ]
    },
    APARTMENT_INTERIOR: {
        C1_MADARIN: [
            `${baseProjectPath}/APARTMENT_INTERIOR/0315_-_C1_MADARIN/0315_-_C1_MADARIN2.jpg`,
            `${baseProjectPath}/APARTMENT_INTERIOR/0315_-_C1_MADARIN/0315_-_C1_MADARIN21.jpg`
        ],
        B2_MADARIN: [
            `${baseProjectPath}/APARTMENT_INTERIOR/0607_-_B2_MADARIN/0607_-_B2_MADARINCHI_HANH.jpg`,
            `${baseProjectPath}/APARTMENT_INTERIOR/0607_-_B2_MADARIN/0607_-_B2_MADARINCHI_HANH_1.jpg`
        ],
        MADARIN_1105B: [
            `${baseProjectPath}/APARTMENT_INTERIOR/1105-B_MADARIN/1105-B_MADARINTRAMI.jpg`,
            `${baseProjectPath}/APARTMENT_INTERIOR/1105-B_MADARIN/1105-B_MADARINTRAMI1.jpg`
        ],
        TRUONG_CHINH_188: [
            `${baseProjectPath}/APARTMENT_INTERIOR/188_TRUONG_CHINH/188_TRUONG_CHINH1.jpg`,
            `${baseProjectPath}/APARTMENT_INTERIOR/188_TRUONG_CHINH/188_TRUONG_CHINH2.jpg`,
            `${baseProjectPath}/APARTMENT_INTERIOR/188_TRUONG_CHINH/188_TRUONG_CHINH3.jpg`
        ],
        CC_PHAN_DINH_PHUNG: [
            `${baseProjectPath}/APARTMENT_INTERIOR/CC_PHAN_DINH_PHUNG/CC_PHAN_DINH_PHUNG1.jpg`,
            `${baseProjectPath}/APARTMENT_INTERIOR/CC_PHAN_DINH_PHUNG/CC_PHAN_DINH_PHUNG2.jpg`,
            `${baseProjectPath}/APARTMENT_INTERIOR/CC_PHAN_DINH_PHUNG/CC_PHAN_DINH_PHUNG3.jpg`,
            `${baseProjectPath}/APARTMENT_INTERIOR/CC_PHAN_DINH_PHUNG/CC_PHAN_DINH_PHUNG4.jpg`
        ],
        THE_MANOR: [
            `${baseProjectPath}/APARTMENT_INTERIOR/THE_MANOR/THE_MANOR01_The_Manor_2010.jpg`
        ]
    },
    HOTEL: {
        EMERARD: [
            `${baseProjectPath}/HOTEL/EMERARD/EMERARD1b.jpg`,
            `${baseProjectPath}/HOTEL/EMERARD/EMERARD2a.jpg`,
            `${baseProjectPath}/HOTEL/EMERARD/EMERARD3a.jpg`
        ],
        ESSENCE: [
            `${baseProjectPath}/HOTEL/ESSENCE/ESSENCE01_22_TH_2009.jpg`
        ],
        HUONG_XUAN: [
            `${baseProjectPath}/HOTEL/KHACH_SAN_HUONG_XUAN/KHACH_SAN_HUONG_XUAN1.jpg`,
            `${baseProjectPath}/HOTEL/KHACH_SAN_HUONG_XUAN/KHACH_SAN_HUONG_XUANKSLC2.jpg`,
            `${baseProjectPath}/HOTEL/KHACH_SAN_HUONG_XUAN/KHACH_SAN_HUONG_XUANKSLC3.jpg`
        ],
        THANH_AN: [
            `${baseProjectPath}/HOTEL/KHACH_SAN_THANH_AN/KHACH_SAN_THANH_AN1.jpg`,
            `${baseProjectPath}/HOTEL/KHACH_SAN_THANH_AN/KHACH_SAN_THANH_ANKSTA3.jpg`
        ],
        TRAN_NGUYEN_HAN: [
            `${baseProjectPath}/HOTEL/KS_TRAN_NGUYEN_HAN/KS_TRAN_NGUYEN_HAN1.jpg`,
            `${baseProjectPath}/HOTEL/KS_TRAN_NGUYEN_HAN/KS_TRAN_NGUYEN_HAN2.jpg`,
            `${baseProjectPath}/HOTEL/KS_TRAN_NGUYEN_HAN/KS_TRAN_NGUYEN_HAN3.jpg`
        ],
        TTTM_THANH_HOA: [
            `${baseProjectPath}/HOTEL/_KHACH_SAN_-_TTTM_THANH_HOA/_KHACH_SAN_-_TTTM_THANH_HOA1.jpg`
        ]
    },
    INTERIOR: {
        FPT: [
            `${baseProjectPath}/INTERIOR/FPT/FPT1.jpg`,
            `${baseProjectPath}/INTERIOR/FPT/FPT2.jpg`
        ],
        GAMI: [
            `${baseProjectPath}/INTERIOR/GAMI/GAMI1.jpg`,
            `${baseProjectPath}/INTERIOR/GAMI/GAMI2.jpg`,
            `${baseProjectPath}/INTERIOR/GAMI/GAMI3.jpg`
        ],
        HANG_DA_GALLERIA: [
            `${baseProjectPath}/INTERIOR/HANG_DA_GALLERIA/HANG_DA_GALLERIA1.jpg`,
            `${baseProjectPath}/INTERIOR/HANG_DA_GALLERIA/HANG_DA_GALLERIA2.jpg`,
            `${baseProjectPath}/INTERIOR/HANG_DA_GALLERIA/HANG_DA_GALLERIA3.jpg`
        ],
        LEASING_VINASHIN: [
            `${baseProjectPath}/INTERIOR/LEASING_-_VINASHIN/LEASING_-_VINASHIN1.jpg`,
            `${baseProjectPath}/INTERIOR/LEASING_-_VINASHIN/LEASING_-_VINASHIN2.jpg`
        ],
        VIETTEL_HI_TECH: [
            `${baseProjectPath}/INTERIOR/VIETTEL_HI-TECH/VIETTEL_HI-TECH1.jpg`,
            `${baseProjectPath}/INTERIOR/VIETTEL_HI-TECH/VIETTEL_HI-TECH2.jpg`
        ],
        VPBANK: [
            `${baseProjectPath}/INTERIOR/VPBANK/VPBANK1.jpg`,
            `${baseProjectPath}/INTERIOR/VPBANK/VPBANK2.jpg`
        ]
    },
    OFFICE: {
        EVD_BUILDING: [
            `${baseProjectPath}/OFFICE_HEADQUARTERS_-_COMMERCIAL_CENTER/EVD_BUILDING/EVD_BUILDING1.jpg`,
            `${baseProjectPath}/OFFICE_HEADQUARTERS_-_COMMERCIAL_CENTER/EVD_BUILDING/EVD_BUILDING2.jpg`
        ],
        PARTS_TOWER: [
            `${baseProjectPath}/OFFICE_HEADQUARTERS_-_COMMERCIAL_CENTER/PARTS_TOWER/PARTS_TOWER1.jpg`,
            `${baseProjectPath}/OFFICE_HEADQUARTERS_-_COMMERCIAL_CENTER/PARTS_TOWER/PARTS_TOWER2.jpg`
        ],
        HOUSING_BUILDING: [
            `${baseProjectPath}/OFFICE_HEADQUARTERS_-_COMMERCIAL_CENTER/TOÀ_NHÀ_HOUSING/TOÀ_NHÀ_HOUSING1.jpg`,
            `${baseProjectPath}/OFFICE_HEADQUARTERS_-_COMMERCIAL_CENTER/TOÀ_NHÀ_HOUSING/TOÀ_NHÀ_HOUSING2.jpg`
        ],
        TRUNG_TAM_TM_VP: [
            `${baseProjectPath}/OFFICE_HEADQUARTERS_-_COMMERCIAL_CENTER/_TRUNG_TAM_TM_-_VP_CHO_THUE/_TRUNG_TAM_TM_-_VP_CHO_THUE1.jpg`,
            `${baseProjectPath}/OFFICE_HEADQUARTERS_-_COMMERCIAL_CENTER/_TRUNG_TAM_TM_-_VP_CHO_THUE/_TRUNG_TAM_TM_-_VP_CHO_THUE2.jpg`
        ],
        LAC_LONG_QUAN_380: [
            `${baseProjectPath}/OFFICE_HEADQUARTERS/380_LAC_LONG_QUAN/380_LAC_LONG_QUAN1.jpg`,
            `${baseProjectPath}/OFFICE_HEADQUARTERS/380_LAC_LONG_QUAN/380_LAC_LONG_QUAN2.jpg`
        ],
        FPT_TAN_THUAN: [
            `${baseProjectPath}/OFFICE_HEADQUARTERS/FPT_TAN_THUAN/FPT_TAN_THUAN1.jpg`,
            `${baseProjectPath}/OFFICE_HEADQUARTERS/FPT_TAN_THUAN/FPT_TAN_THUAN2.jpg`
        ],
        MOBIFONE: [
            `${baseProjectPath}/OFFICE_HEADQUARTERS/MOBIFONE/MOBIFONE1.jpg`,
            `${baseProjectPath}/OFFICE_HEADQUARTERS/MOBIFONE/MOBIFONE2.jpg`
        ],
        VOV5: [
            `${baseProjectPath}/OFFICE_HEADQUARTERS/_TRU_SO_VOV5/_TRU_SO_VOV51.jpg`,
            `${baseProjectPath}/OFFICE_HEADQUARTERS/_TRU_SO_VOV5/_TRU_SO_VOV52.jpg`
        ]
    }
};

export const getProjectImages = (category, project) => {
    if (!projectImages[category] || !projectImages[category][project]) {
        return [];
    }
    return projectImages[category][project];
};

export const getAllProjectImages = () => {
    return Object.entries(projectImages).reduce((acc, [category, projects]) => {
        Object.entries(projects).forEach(([project, images]) => {
            acc.push({
                category,
                project,
                images
            });
        });
        return acc;
    }, []);
};