export const clients = [
  { name: 'VIETTEL', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=VIETTEL' },
  { name: 'FPT', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=FPT' },
  { name: 'MW ZANDER', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=MW+ZANDER' },
  { name: 'VOV', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=VOV' },
  { name: 'TECHCOMBANK', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=TECHCOMBANK' },
  { name: 'BERJAYA HANDICO 12', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=BERJAYA' },
  { name: 'EMICO', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=EMICO' },
  { name: 'VPBANK', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=VPBANK' },
  { name: 'B/BRAUN', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=B/BRAUN' },
  { name: 'VNF1', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=VNF1' },
  { name: 'GAMI GROUP', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=GAMI' },
  { name: 'SUMITOMO MITSUI', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=SUMITOMO' },
  { name: 'HAPRO', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=HAPRO' },
  { name: 'TONKIN GROUP', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=TONKIN' },
  { name: 'KINDER WORLD', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=KINDER+WORLD' },
  { name: 'VINACOMIN', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=VINACOMIN' },
  { name: 'EVD', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=EVD' },
  { name: 'TECHVILLE', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=TECHVILLE' },
  { name: 'MOBIFONE', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=MOBIFONE' },
  { name: 'HOUSING', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=HOUSING' },
  { name: 'VINASHIN', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=VINASHIN' },
  { name: 'MATH INSTITUTION', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=MATH+INST' },
  { name: 'THANGLONG-CIENCO1', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=THANGLONG' },
  { name: 'MILITARY SCIENCE INSTITUTION', logo: 'https://placehold.co/200x100/FFFFFF/006491?text=MILITARY+SCIENCE' }
];