export const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

export const preloadImages = async (images) => {
  try {
    const promises = images.map(src => preloadImage(src));
    await Promise.all(promises);
    return true;
  } catch (error) {
    console.error('Error preloading images:', error);
    return false;
  }
}; 