export const textTranslations = {
  en: {
    navigation: {
      home: "Home",
      projects: "Projects",
      about: "About",
      contact: "Contact"
    },
    ui: {
      toggleNav: "Toggle navigation",
      close: "Close",
      featuredProjects: "Featured Projects",
      ourClients: "Our Clients",
      viewMore: "View More",
      prev: "Previous",
      next: "Next",
      loading: "Loading...",
      success: "Message sent successfully!",
      error: "Error sending message. Please try again."
    },
    contact: {
      title: "Contact Us",
      form: {
        name: "Name",
        email: "Email",
        message: "Message",
        submit: "Submit"
      }
    },
    main: {
      philosophy: [
        {
          title: "Perfectly Design",
          text: "We believe in creating architectural designs that perfectly balance aesthetics, functionality, and sustainability. Every detail is carefully considered to create spaces that inspire and endure."
        },
        {
          title: "Carefully Planned",
          text: "Our meticulous planning process ensures that every project is executed with precision. We consider all aspects from urban context to environmental impact, creating comprehensive solutions."
        },
        {
          title: "Smartly Execute",
          text: "Through innovative construction methods and smart project management, we transform architectural visions into reality. Our execution combines technical expertise with practical efficiency."
        }
      ],
      vision: {
        title: "Vision",
        description: "To be Vietnam's leading architectural design and construction solutions provider, creating sustainable and innovative spaces that enhance lives."
      },
      experience: {
        title: "Experience",
        description: "With years of expertise in architectural design and construction, we deliver excellence through proven methodologies and skilled professionals."
      },
      innovation: {
        title: "Innovation",
        description: "Embracing cutting-edge technology and creative solutions to push the boundaries of architectural design and construction efficiency."
      },
      cooperation: {
        title: "Cooperation",
        description: "Building strong partnerships with clients, contractors, and stakeholders to ensure project success through collaborative excellence."
      },
      services: [
        {
          title: "Architectural Design",
          description: "Comprehensive architectural design services for various building types, from concept to detailed drawings."
        },
        {
          title: "Construction Design",
          description: "Full construction design documentation including structural, mechanical, and electrical systems."
        },
        {
          title: "Construction Management",
          description: "Professional oversight of construction projects ensuring quality, timeline, and budget compliance."
        },
        {
          title: "Technical Infrastructure",
          description: "Design and implementation of essential building infrastructure and utility systems."
        },
        {
          title: "Project Assessment",
          description: "Thorough evaluation and feasibility studies for construction projects and developments."
        },
        {
          title: "Construction Consulting",
          description: "Expert advice on construction methods, materials, and project optimization strategies."
        },
        {
          title: "Project Planning",
          description: "Strategic planning and development of construction projects from inception to completion."
        },
        {
          title: "Technical Solutions",
          description: "Innovative technical solutions for complex architectural and construction challenges."
        }
      ]
    },
    companyInfo: {
      name: "Architecture and high Building Solutions Corporation",
      shortName: "ABS",
      address: "5th floor, HOUSING building, #299 Trung Kinh street, Yen Hoa ward, Cau Giay district, Hanoi city, Viet Nam.",
      tel: "+84.4.37263569",
      fax: "+84.3.7263570",
      email: "absvietnam@gmail.com",
      website: "www.absvn.com",
      capital: "8.860.000.000 vnd",
      bankAccount: "No. 0611001461813 in VietcomBank, Ba Dinh Branch, Hanoi, Vietnam",
      businessReg: "0103018772",
      taxCode: "0102343574",
      philosophy: "Architecture and high Building Solutions Corporation (ABS) is one of prestigious companies and gradually build up reputation in architecture and construction in Vietnam. Being established in 2007 by experienced, competent and enthusiastic members, ABS has been trying his best continuously to bring clients consulting services at the highest quality and the most intelligent and effective solutions. We have created our own path which is oriented to the modern architectural trend, appropriate with regional economic conditions, technologies as well as climate conditions. Specializing in design consultant, ABS always commits to implement projects successfully and take sustainable values to each projects. We understand and believe that the value of the prestige creation through the service quality and satisfaction to the clients' increasing strict requirements will be the solid foundation for our sustainable development. ABS has a modern way of thinking and a systematic working style. We are ready to share knowledge and develop working skills constantly and create new things continuously. We are eager to co-operate with domestic and foreign businesses for jointly development.",
      mainActivities: [
        "Investment project planning consulting",
        "Design consultant",
        "Master planning design",
        "Architecture design",
        "Interior design",
        "Landscape design",
        "Supervision and Construction Management"
      ],
      team: [
        "Tran Hong Thuy", "Đinh Cong Quynh", "Tran Minh Dzung", "Nguyen The Tai", "Le Anh Tung", "Tran Thai Duc",
        "Pham Duc Cong", "Tran Dang Hinh","Bui Thanh Chung", "Nguyen Thi Thu", "Nguyen Van Xiem", "Vu Manh Đuc",
        "Nguyen Thi Hue", "Nguyen Ba Dzung", "Do Thuy Duong"
      ],
      cooperation: [
        "Civil Engineer University",
        "HOUSING investment and construction corporation",
        "GROUP 8 – Swiss",
        "FORMAT – Denmark",
        "DP Architects - Malaysia"
      ],
      target: "\"We are the architects for professional clients\" To affirm our position in consultant services in high building projects domestically and abroad.",
      clients: [
        "VIETTEL", "FPT", "MW ZANDER VIETNAM",
        "VOV", "TECHCOMBANK", "BERJAYA HANDICO 12",
        "EMICO", "VPBANK", "B/BRAUN",
        "VNF1", "GAMI GROUP", "SUMITOMO MITSUI",
        "HAPRO", "TONKIN GROUP", "KINDER WORLD",
        "VINACOMIN", "EVD", "TECHVILLE",
        "MOBIFONE", "HOUSING", "VINASHIN",
        "MATH INSTITUTION", "THANGLONG-CIENCO1", "MILITARY SCIENCE INSTITUTION"
      ]
    }
  },
  vi: {
    navigation: {
      home: "Trang chủ",
      projects: "Dự án",
      about: "Giới thiệu",
      contact: "Liên hệ"
    },
    ui: {
      toggleNav: "Chuyển đổi điều hướng",
      close: "Đóng",
      featuredProjects: "Dự án tiêu biểu",
      ourClients: "Khách hàng",
      viewMore: "Xem thêm",
      prev: "Trước",
      next: "Tiếp",
      loading: "Đang gửi...",
      success: "Gửi tin nhắn thành công!",
      error: "Lỗi khi gửi tin nhắn. Vui lòng thử lại."
    },
    contact: {
      title: "Liên hệ với chúng tôi",
      form: {
        name: "Họ tên",
        email: "Email",
        message: "Tin nhắn",
        submit: "Gửi"
      }
    },
    main: {
      philosophy: [
        {
          title: "Thiết kế hoàn hảo",
          text: "Chúng tôi tin tưởng vào việc tạo ra các thiết kế kiến trúc cân bằng hoàn hảo giữa thẩm mỹ, chức năng và tính bền vững. Mọi chi tiết đều được cân nhắc kỹ lưỡng để tạo ra không gian truyền cảm hứng và bền vững."
        },
        {
          title: "Lập kế hoạch cẩn thận",
          text: "Quy trình lập kế hoạch tỉ mỉ của chúng tôi đảm bảo mọi dự án được thực hiện với độ chính xác cao. Chúng tôi xem xét mọi khía cạnh từ bối cảnh đô thị đến tác động môi trường, tạo ra giải pháp toàn diện."
        },
        {
          title: "Thực hiện thông minh",
          text: "Thông qua các phương pháp xây dựng sáng tạo và quản lý dự án thông minh, chúng tôi biến tầm nhìn kiến trúc thành hiện thực. Việc thực hiện của chúng tôi kết hợp chuyên môn kỹ thuật với hiệu quả thực tế."
        }
      ],
      vision: {
        title: "Tầm nhìn",
        description: "Trở thành nhà cung cấp giải pháp thiết kế kiến trúc và xây dựng hàng đầu Việt Nam, tạo ra không gian bền vững và sáng tạo nâng cao chất lượng cuộc sống."
      },
      experience: {
        title: "Kinh nghiệm",
        description: "Với nhiều năm kinh nghiệm trong thiết kế kiến trúc và xây dựng, chúng tôi mang đến sự xuất sắc thông qua phương pháp đã được chứng minh và đội ngũ chuyên gia giàu kinh nghiệm."
      },
      innovation: {
        title: "Đổi mới",
        description: "Áp dụng công nghệ tiên tiến và giải pháp sáng tạo để mở rộng ranh giới của thiết kế kiến trúc và hiệu quả xây dựng."
      },
      cooperation: {
        title: "Hợp tác",
        description: "Xây dựng quan hệ đối tác mạnh mẽ với khách hàng, nhà thầu và các bên liên quan để đảm bảo thành công dự án thông qua sự xuất sắc trong hợp tác."
      },
      services: [
        {
          title: "Thiết kế kiến trúc",
          description: "Dịch vụ thiết kế kiến trúc toàn diện cho các loại công trình, từ ý tưởng đến bản vẽ chi tiết."
        },
        {
          title: "Thiết kế xây dựng",
          description: "Tài liệu thiết kế xây dựng đầy đủ bao gồm hệ thống kết cấu, cơ khí và điện."
        },
        {
          title: "Quản lý xây dựng",
          description: "Giám sát chuyên nghiệp các dự án xây dựng đảm bảo chất lượng, tiến độ và tuân thủ ngân sách."
        },
        {
          title: "Hạ tầng kỹ thuật",
          description: "Thiết kế và triển khai hạ tầng công trình và hệ thống tiện ích thiết yếu."
        },
        {
          title: "Đánh giá dự án",
          description: "Đánh giá kỹ lưỡng và nghiên cứu khả thi cho các dự án xây dựng và phát triển."
        },
        {
          title: "Tư vấn xây dựng",
          description: "Tư vấn chuyên môn về phương pháp xây dựng, vật liệu và chiến lược tối ưu hóa dự án."
        },
        {
          title: "Lập kế hoạch dự án",
          description: "Lập kế hoạch chiến lược và phát triển dự án xây dựng từ khởi đầu đến hoàn thành."
        },
        {
          title: "Giải pháp kỹ thuật",
          description: "Giải pháp kỹ thuật sáng tạo cho các thách thức phức tạp trong kiến trúc và xây dựng."
        }
      ]
    },
    companyInfo: {
      name: "Công ty Cổ phần Kiến trúc và Giải pháp Công trình cao tầng",
      shortName: "ABS",
      address: "Tầng 5, tòa nhà HOUSING, số 299 đường Trung Kính, phường Yên Hòa, quận Cầu Giấy, thành phố Hà Nội, Việt Nam.",
      tel: "+84.4.37263569",
      fax: "+84.3.7263570",
      email: "absvietnam@gmail.com",
      website: "www.absvn.com",
      capital: "8.860.000.000 vnd",
      bankAccount: "Số 0611001461813 tại Ngân hàng Vietcombank, Chi nhánh Ba Đình, Hà Nội, Việt Nam",
      businessReg: "0103018772",
      taxCode: "0102343574",
      philosophy: "Công ty Cổ phần Kiến trúc và Giải pháp Công trình cao tầng (ABS) là một trong những công ty uy tín và đang dần xây dựng danh tiếng trong lĩnh vực kiến trúc và xây dựng tại Việt Nam. Được thành lập vào năm 2007 bởi các thành viên giàu kinh nghiệm, năng lực và nhiệt huyết, ABS luôn nỗ lực không ngừng để mang đến cho khách hàng dịch vụ tư vấn chất lượng cao nhất với những giải pháp thông minh và hiệu quả nhất. Chúng tôi đã tạo ra con đường riêng hướng đến xu hướng kiến trúc hiện đại, phù hợp với điều kiện kinh tế khu vực, công nghệ cũng như điều kiện khí hậu. Chuyên về tư vấn thiết kế, ABS luôn cam kết thực hiện thành công các dự án và mang lại giá trị bền vững cho từng công trình. Chúng tôi hiểu và tin rằng giá trị của việc tạo dựng uy tín thông qua chất lượng dịch vụ và sự hài lòng đối với các yêu cầu ngày càng khắt khe của khách hàng sẽ là nền tảng vững chắc cho sự phát triển bền vững của chúng tôi. ABS có cách tư duy hiện đại và phong cách làm việc có hệ thống. Chúng tôi sẵn sàng chia sẻ kiến thức và không ngừng phát triển kỹ năng làm việc, liên tục sáng tạo những điều mới. Chúng tôi mong muốn hợp tác với các doanh nghiệp trong và ngoài nước để cùng phát triển.",
      mainActivities: [
        "Tư vấn lập kế hoạch dự án đầu tư",
        "Tư vấn thiết kế",
        "Thiết kế quy hoạch tổng thể",
        "Thiết kế kiến trúc",
        "Thiết kế nội thất",
        "Thiết kế cảnh quan",
        "Giám sát và Quản lý xây dựng"
      ],
      team: [
        "Trần Hồng Thủy", "Đinh Công Quỳnh", "Trần Minh Dũng", "Nguyễn Thế Tài", "Lê Anh Tùng", "Trần Thái Đức",
        "Phạm Đức Công", "Trần Đăng Hình","Bùi Thanh Chung", "Nguyễn Thị Thu", "Nguyễn Văn Xiêm", "Vũ Mạnh Đức",
        "Nguyễn Thị Huệ", "Nguyễn Bá Dũng", "Đỗ Thùy Dương"
      ],
      cooperation: [
        "Đại học Xây dựng",
        "Tổng công ty đầu tư và xây dựng HOUSING",
        "GROUP 8 – Thụy Sĩ",
        "FORMAT – Đan Mạch",
        "DP Architects - Malaysia"
      ],
      target: "\"Chúng tôi là kiến trúc sư cho các khách hàng chuyên nghiệp\" Để khẳng định vị thế của chúng tôi trong dịch vụ tư vấn các dự án công trình cao tầng trong nước và quốc tế.",
      clients: [
        "VIETTEL", "FPT", "MW ZANDER VIETNAM",
        "VOV", "TECHCOMBANK", "BERJAYA HANDICO 12",
        "EMICO", "VPBANK", "B/BRAUN",
        "VNF1", "GAMI GROUP", "SUMITOMO MITSUI",
        "HAPRO", "TONKIN GROUP", "KINDER WORLD",
        "VINACOMIN", "EVD", "TECHVILLE",
        "MOBIFONE", "HOUSING", "VINASHIN",
        "MATH INSTITUTION", "THANGLONG-CIENCO1", "MILITARY SCIENCE INSTITUTION"
      ]
    }
  }
};

// For backward compatibility
export const companyInfo = textTranslations.en.companyInfo;