// Structure for multilingual project data
export const projectTranslations = {
  en: {
    buildingTypes: {
      'Public Building': 'Public Building',
      'Residential House': 'Residential House',
      'Master Planning and Landscaping': 'Master Planning and Landscaping',
      'Interior Design': 'Interior Design',
      'Out sourcing': 'Out Sourcing'
    }
  },
  vi: {
    buildingTypes: {
      'Public Building': 'Công Trình Công Cộng',
      'Residential House': 'Nhà Ở',
      'Master Planning and Landscaping': 'Quy Hoạch Tổng Thể và Cảnh Quan',
      'Interior Design': 'Thiết Kế Nội Thất',
      'Out sourcing': 'Dịch Vụ Thuê Ngoài'
    }
  }
};

import { getProjectImages } from './projectImages';

export const projects = [
    {
      id: 1,
      type: 'Public Building',
      name: 'EVD Building, 8 Tran Hung Dao, Hoan Kiem, Ha Noi',
      year: '2008',
      image: getProjectImages('OFFICE', 'EVD_BUILDING'),
      description: 'Modern office building with commercial spaces',
    },
    {
      id: 2,
      type: 'Public Building',
      name: 'ITASCO Tower, 48 Lang Ha, Dong Da, Ha Noi',
      year: '2009',
      image: getProjectImages('APARTMENT', 'ITASCO_TOWER'),
      description: 'Contemporary residential tower with premium amenities',
    },
    {
      id: 3,
      type: 'Interior Design',
      name: 'FPT Office Interior, Duy Tan, Cau Giay, Ha Noi',
      year: '2010',
      image: getProjectImages('INTERIOR', 'FPT'),
      description: 'Modern tech office interior design',
    },
    {
      id: 4,
      type: 'Public Building',
      name: 'Emerald Hotel, 195 Dien Bien Phu, Da Nang',
      year: '2009',
      image: getProjectImages('HOTEL', 'EMERARD'),
      description: 'Luxury hotel with modern amenities',
    },
    {
        id: 5,
        type: 'Public Building',
        name: 'Techcombank Kim Thanh building – Lao Cai',
        year: '2009',
        image: getProjectImages('OFFICE', 'PARTS_TOWER'),
        description: 'Description for Techcombank Kim Thanh building – Lao Cai',
      },
      {
          id: 6,
          type: 'Public Building',
          name: 'Viettel 380 Lac Long Quan building – 380 Lac Long Quan, Tay Ho, Ha Noi',
          year: '2010',
          image: getProjectImages('OFFICE', 'LAC_LONG_QUAN_380'),
          description: 'Description for Viettel 380 Lac Long Quan building – 380 Lac Long Quan, Tay Ho, Ha Noi',
        },
        {
          id: 7,
          type: 'Public Building',
          name: 'Radio the Voice of Vietnam building – 37 Ba Trieu, Hoan Kiem, Ha Noi',
          year: '2010',
          image: getProjectImages('OFFICE', 'VOV5'),
          description: 'Description for Radio the Voice of Vietnam building – 37 Ba Trieu, Hoan Kiem, Ha Noi',
        },
      {
          id: 8,
          type: 'Public Building',
          name: 'Viettel  Nghe An office building - Vinh, Nghe An',
          year: '2010',
          image: getProjectImages('OFFICE', 'TRUNG_TAM_TM_VP'),
          description: 'Description for Viettel  Nghe An office building - Vinh, Nghe An',
      },
      {
          id: 9,
          type: 'Public Building',
          name: 'HOUSING Building, 155 Cau Giay, Ha Noi',
          year: '2010',
          image: getProjectImages('OFFICE', 'HOUSING_BUILDING'),
          description: 'Description for HOUSING building, Cau Giay, Ha Noi',
        },
        {
          id: 10,
          type: 'Public Building',
          name: 'Residential CT5, Ha Noi Garden City, Thach Ban, Long Bien, Ha Noi',
          year: '2011',
          image: getProjectImages('APARTMENT', 'CHUNG_CU_CT5_AVENUE_PARK'),
          description: 'Description for Residential CT5, Ha Noi Garden City, Thach Ban, Ha Noi',
        },
        {
          id: 49,
          type: 'Public Building',
          name: 'Viettel Ca Mau Office Building, 132 Nguyen Tat Thanh, Ca Mau',
          year: '2011',
          image: getProjectImages('OFFICE', 'TRUNG_TAM_TM_VP'),
          description: 'Description for Viettel Ca Mau office building, Ca Mau'
        },
        {
          id: 50,
          type: 'Public Building',
          name: 'ITASCO Residential and Office Building, 98 Nguyen Trai, Thanh Xuan, Ha Noi',
          year: '2011',
          image: getProjectImages('APARTMENT', 'ITASCO_TOWER'),
          description: 'Description for ITASCO Residential and Office Building, Thanh Xuan, Ha Noi'
        },
          {
          id: 11,
          type: 'Public Building',
          name: 'Hapro shopping mall and office – 376 Kham Thien, Dong Da, Ha Noi',
          year: '2011',
          image: getProjectImages('INTERIOR', 'HANG_DA_GALLERIA'),
          description: 'Description for Hapro shopping mall and office – 376 Kham Thien, Dong Da, Ha Noi'
        },
        {
          id: 12,
          type: 'Public Building',
          name: 'FPT Tan Thuan – District 7, HCM city',
          year: '2012',
          image: getProjectImages('OFFICE', 'FPT_TAN_THUAN'),
          description: 'Description for FPT Tan Thuan – District 7, HCM city',
        },
      {
          id: 13,
          type: 'Public Building',
          name: 'VNF1 Hotel – Hai Phong',
          year: '2012',
          image: getProjectImages('HOTEL', 'EMERARD'),
          description: 'Description for VNF1 Hotel – Hai Phong',
        },
        {
          id: 14,
          type: 'Public Building',
          name: 'Villas Ha Noi Garden City – Thach Ban, Ha Noi',
          year: '2012',
          image: getProjectImages('APARTMENT', 'CHUNG_CU_CT5_AVENUE_PARK'),
          description: 'Description for Villas Ha Noi Garden City – Thach Ban, Ha Noi',
        },
        {
            id: 15,
            type: 'Public Building',
            name: 'Le Huu Lap shopping mall, hotel – Thanh Hoa',
            year: '2012',
            image: getProjectImages('HOTEL', 'TTTM_THANH_HOA'),
            description: 'Description for Le Huu Lap shopping mall, hotel – Thanh Hoa',
          },
          {
            id: 16,
            type: 'Public Building',
            name: 'Viettel Hậu Giang office building – Vi Thanh, Hau Giang',
            year: '2013',
            image: getProjectImages('OFFICE', 'TRUNG_TAM_TM_VP'),
            description: 'Description for Viettel Hậu Giang office building – Vi Thanh, Hau Giang',
          },
      {
          id: 17,
          type: 'Public Building',
          name: 'Techcombank Coc Leu Building – Coc Leu, Lao Cai',
          year: '2013',
          image: getProjectImages('OFFICE', 'PARTS_TOWER'),
          description: 'Description for Techcombank Coc Leu Building – Coc Leu, Lao Cai'
        },
        {
          id: 18,
          type: 'Public Building',
          name: 'Military Science Institution library – Lai Xa, Ha Noi',
          year: '2014',
          image: getProjectImages('SCHOOL', 'KHU_HOC_VIEN_QUOC_TE_HVKHQS'),
          description: 'Description for Military Science Institution library – Lai Xa, Ha Noi'
        },
        {
          id: 19,
          type: 'Public Building',
          name: 'Military Science Institution gymnasium – Hoang Mai, Ha Noi',
          year: '2014',
          image: getProjectImages('SCHOOL', 'NHA_THE_THAO_DA_NANG'),
          description: 'Description for Military Science Institution gymnasium – Hoang Mai, Ha Noi'
        },
        {
            id: 20,
            type: 'Public Building',
            name: 'Viettel Tay Ninh office building – Tay Ninh',
            year: '2014',
            image: getProjectImages('OFFICE', 'TRUNG_TAM_TM_VP'),
            description: 'Description for Viettel Tay Ninh office building – Tay Ninh',
        },
      {
          id: 21,
          type: 'Public Building',
          name: 'Viettel Binh Duong offce building – Thu Dau Mot, Binh Duong',
          year: '2014',
          image: getProjectImages('OFFICE', 'TRUNG_TAM_TM_VP'),
          description: 'Description for Viettel Binh Duong offce building – Thu Dau Mot, Binh Duong',
      },
      {
        id: 22,
        type: 'Public Building',
        name: 'Viettel Vinh Long – Vinh Long',
        year: '2014',
        image: getProjectImages('OFFICE', 'TRUNG_TAM_TM_VP'),
        description: 'Description for Viettel Vinh Long – Vinh Long',
      },
      {
        id: 23,
        type: 'Public Building',
        name: 'Mobifone Node 3 building – District 9, HCM city',
        year: '2014',
        image: getProjectImages('OFFICE', 'MOBIFONE'),
        description: 'Description for Mobifone Node 3 building – District 9, HCM city',
      },
        {
            id: 24,
            type: 'Public Building',
            name: 'Internation Institution  – Lai Xa, Ha Noi',
            year: '2014',
            image: getProjectImages('SCHOOL', 'KHU_HOC_VIEN_QUOC_TE_HVKHQS'),
            description: 'Description for Internation Institution  – Lai Xa, Ha Noi'
        },
        {
          id: 25,
          type: 'Public Building',
          name: 'Military Science Institution residential – Hoang Mai, Ha Noi',
          year: '2015',
          image: getProjectImages('APARTMENT', 'NHA_CONG_VU_HVKH_QUAN_SU'),
          description: 'Description for Military Science Institution residential – Hoang Mai, Ha Noi',
        },
    {
          id: 26,
          type: 'Residential House',
          name: 'Private Villa – Chem, Tu Liem, Ha Noi',
          year: '2010',
          image: getProjectImages('APARTMENT', 'NHA_HE_135'),
          description: 'Description for Private Villa – Chem, Tu Liem, Ha Noi',
      },
      {
          id: 27,
          type: 'Residential House',
          name: 'Beach Villa –Ha Long, Quang Ninh',
          year: '2011',
          image: getProjectImages('APARTMENT', 'NHA_HE_135'),
          description: 'Description for Beach Villa –Ha Long, Quang Ninh',
      },
      {
          id: 28,
          type: 'Residential House',
          name: 'Serviced Apartment building – 31 Pho Duc Chinh, Ba Dinh, Ha Noi',
          year: '2012',
          image: getProjectImages('APARTMENT_INTERIOR', 'CC_PHAN_DINH_PHUNG'),
          description: 'Description for Serviced Apartment building – 31 Pho Duc Chinh, Ba Dinh, Ha Noi',
      },
      {
          id: 29,
          type: 'Residential House',
          name: 'Serviced Apartment building – 33b Pham Ngu Lao, Hoan Kiem, Ha Noi',
          year: '2012',
          image: getProjectImages('APARTMENT_INTERIOR', 'THE_MANOR'),
          description: 'Description for Serviced Apartment building – 33b Pham Ngu Lao, Hoan Kiem, Ha Noi',
      },
        {
            id: 30,
            type: 'Residential House',
            name: 'Private building – 47 Phan Dinh Phung, Ba Dinh, Hà Noi',
            year: '2013',
            image: getProjectImages('APARTMENT_INTERIOR', 'CC_PHAN_DINH_PHUNG'),
            description: 'Description for Private building – 47 Phan Dinh Phung, Ba Dinh, Hà Noi',
      },
      {
          id: 31,
          type: 'Residential House',
          name: 'Twin Beach Villa –Ha Long, Quang Ninh',
          year: '2013',
          image: getProjectImages('APARTMENT', 'NHA_HE_135'),
          description: 'Description for Twin Beach Villa –Ha Long, Quang Ninh',
      },
      {
        id: 32,
        type: 'Residential House',
        name: 'Private Villa – Thuy Phuong, Tu Liem, Ha Noi',
        year: '2014',
        image: getProjectImages('APARTMENT', 'NHA_HE_135'),
        description: 'Description for Private Villa – Thuy Phuong, Tu Liem, Ha Noi',
      },
       {
          id: 33,
          type: 'Residential House',
          name: 'Beach Villa – Cai Ram, Bai Chay, Quang Ninh',
          year: '2014',
          image: getProjectImages('APARTMENT', 'NHA_HE_135'),
          description: 'Description for Beach Villa – Cai Ram, Bai Chay, Quang Ninh',
      },
      {
        id: 34,
        type: 'Residential House',
        name: 'Private Villa – Long Bien, Ha Noi',
        year: '2014',
        image: getProjectImages('APARTMENT', 'NHA_HE_135'),
        description: 'Description for Private Villa – Long Bien, Ha Noi',
        },
      {
        id: 35,
        type: 'Master Planning and Landscaping',
        name: 'Landscaping Comercial Trading line along Day River, Phu Ly province, Ha Nam',
        year: '2007',
          image: getProjectImages('LANDSCAPE', 'SONG_DAY_PHU_LY'),
          description: 'Description for Landscaping Comercial Trading line along Day River, Phu Ly province, Ha Nam'
      },
      {
        id: 36,
        type: 'Master Planning and Landscaping',
        name: 'Landscape Design of BBraun F.I.V.E Thanh Oai, Ha Tay',
        year: '2010',
          image: getProjectImages('LANDSCAPE', 'NHA_MAY_THIET_BI_Y_TE_BBRAUN'),
          description: 'Description for Landscape Design of BBraun F.I.V.E Thanh Oai, Ha Tay'
      },
      {
        id: 37,
        type: 'Master Planning and Landscaping',
        name: 'Hai Boi residential planning, Dong Anh, Ha Noi',
        year: '2013',
          image: getProjectImages('LOW_RISE_HOUSING', 'NHA_O_THAP_TANG_HANOI_GARDEN'),
          description: 'Description for Hai Boi residential planning, Dong Anh, Ha Noi'
      },
      {
        id: 38,
        type: 'Master Planning and Landscaping',
        name: 'Quang An shopping mall, hotel complex planning – Tay Ho, Ha Noi',
        year: '2013',
          image: getProjectImages('INTERIOR', 'HANG_DA_GALLERIA'),
          description: 'Description for Quang An shopping mall, hotel complex planning – Tay Ho, Ha Noi'
      },
      {
        id: 39,
        type: 'Master Planning and Landscaping',
        name: 'Military Science Institution planning – Le Trong Tan, Hoang Mai, Ha Noi',
        year: '2015',
          image: getProjectImages('SCHOOL', 'GIANG_DUONG_TRUNG_TAM_HVKHQS'),
          description: 'Description for Military Science Institution planning – Le Trong Tan, Hoang Mai, Ha Noi'
      },
      {
        id: 40,
        type: 'Interior Design',
        name: 'Commercial GAMI office interior, CT3 Pham Van Dong, Cau Giay, Hanoi',
        year: '2007',
          image: getProjectImages('INTERIOR', 'GAMI'),
          description: 'Description for Commercial GAMI office interior, CT3 Pham Van Dong, Cau Giay, Hanoi',
      },
      {
        id: 41,
        type: 'Interior Design',
        name: 'Interior design of 13th floor FPT building, Cau Giay, Ha Noi',
        year: '2008',
          image: getProjectImages('INTERIOR', 'FPT'),
          description: 'Description for Interior design of 13th floor FPT building, Cau Giay, Ha Noi',
      },
       {
        id: 42,
        type: 'Interior Design',
        name: 'Interior design LEASING-VINASHIN office, 34T Trung Hoa Nhan Chinh, Ha Noi',
        year: '2009',
          image: getProjectImages('INTERIOR', 'LEASING_VINASHIN'),
          description: 'Description for Interior design LEASING-VINASHIN office, 34T Trung Hoa Nhan Chinh, Ha Noi',
      },
      {
        id: 43,
        type: 'Interior Design',
        name: 'Interior design High Research Institition of Maths – Ta Quang Buu library, Ha Noi',
        year: '2010',
          image: getProjectImages('INTERIOR', 'VPBANK'),
          description: 'Description for Interior design High Research Institition of Maths – Ta Quang Buu library, Ha Noi'
      },
      {
          id: 44,
          type: 'Interior Design',
          name: 'Interior design HANG DA Galleria fashion center, Cua Dong, Hoan Kiem, Ha Noi',
          year: '2011',
          image: getProjectImages('INTERIOR', 'HANG_DA_GALLERIA'),
          description: 'Description for Interior design HANG DA Galleria fashion center, Cua Dong, Hoan Kiem, Ha Noi',
      },
      {
        id: 45,
        type: 'Interior Design',
        name: 'Interior design bobby of Viettel Hitech buiding, Hoa Lac, Ha Noi',
        year: '2013',
          image: getProjectImages('INTERIOR', 'VIETTEL_HI_TECH'),
          description: 'Description for Interior design bobby of Viettel Hitech buiding, Hoa Lac, Ha Noi',
        },
        {
          id: 46,
          type: 'Out sourcing',
          name: '3D illustration – FORMAT, Denmark',
          year: '',
          image: ['https://images.unsplash.com/photo-1577495508326-19a1b3cf65b7?ixlib=rb-4.0.3'],
          description: 'Description for 3D illustration – FORMAT, Denmark',
        },
        {
          id: 47,
          type: 'Out sourcing',
          name: 'Develop Concept Design – PECASUS Project, Singapore',
          year: '',
          image: ['https://images.unsplash.com/photo-1577495508326-19a1b3cf65b7?ixlib=rb-4.0.3'],
          description: 'Description for Develop Concept Design – PECASUS Project, Singapore',
        },
        {
          id: 48,
          type: 'Out sourcing',
          name: 'Develop Concept Design – TechVille, IDG USA',
          year: '',
          image: ['https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3'],
          description: 'Description for Develop Concept Design – TechVille, IDG USA'
          },
  
  
  ];