import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa';

const FullScreenImage = ({ src, alt, onClose }) => {
  return (
    <motion.div 
      className="fullscreen-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.img
        src={src}
        alt={alt}
        className="fullscreen-image"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
        onClick={(e) => e.stopPropagation()}
      />
      <button className="fullscreen-close" onClick={onClose}>
        <FaTimes />
      </button>
    </motion.div>
  );
};

const ProjectPopup = ({ project, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const images = Array.isArray(project.image) ? project.image : [project.image];

  // Preload all project images when popup opens
  useEffect(() => {
    images.forEach(imgSrc => {
      const img = new Image();
      img.src = imgSrc;
    });
  }, [images]);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('project-popup-overlay')) {
      onClose();
    }
  };

  return (
    <motion.div 
      className="project-popup-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={handleOverlayClick}
    >
      <motion.div 
        className="project-popup-content"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="popup-close" onClick={onClose}>
          <FaTimes />
        </button>

        <div className="popup-image-container">
          <AnimatePresence mode='wait'>
            <motion.img
              key={currentImageIndex}
              src={images[currentImageIndex]}
              alt={`${project.name} - Image ${currentImageIndex + 1}`}
              loading="lazy"
              onLoad={handleImageLoad}
              onClick={() => setFullscreenImage(images[currentImageIndex])}
              initial={{ opacity: 0 }}
              animate={{ opacity: isImageLoaded ? 1 : 0 }}
              exit={{ opacity: 0 }}
              style={{ 
                display: isImageLoaded ? 'block' : 'none',
                cursor: 'zoom-in'
              }}
            />
          </AnimatePresence>
          {!isImageLoaded && (
            <div className="image-placeholder">
              <div className="loading-spinner"></div>
            </div>
          )}

          {images.length > 1 && (
            <>
              <button
                className="popup-nav-button prev"
                onClick={() => setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length)}
              >
                <FaChevronLeft />
              </button>
              <button
                className="popup-nav-button next"
                onClick={() => setCurrentImageIndex((prev) => (prev + 1) % images.length)}
              >
                <FaChevronRight />
              </button>
              <div className="popup-indicators">
                {images.map((_, index) => (
                  <div
                    key={index}
                    className={`indicator ${index === currentImageIndex ? 'active' : ''}`}
                    onClick={() => setCurrentImageIndex(index)}
                  />
                ))}
              </div>
            </>
          )}
        </div>

        <div className="popup-info">
          <h2>{project.name}</h2>
          <p className="popup-location">{project.location}</p>
          <p className="popup-description">{project.description}</p>
          <div className="popup-meta">
            <div>
              <strong>Client</strong>
              <p>{project.client}</p>
            </div>
            <div>
              <strong>Year</strong>
              <p>{project.year}</p>
            </div>
            <div>
              <strong>Type</strong>
              <p>{project.type}</p>
            </div>
            <div>
              <strong>Status</strong>
              <p>{project.status}</p>
            </div>
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        {fullscreenImage && (
          <FullScreenImage
            src={fullscreenImage}
            alt={project.name}
            onClose={() => setFullscreenImage(null)}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ProjectPopup; 