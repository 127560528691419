import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useScroll, useTransform } from 'framer-motion';
import { images } from '../constants/images';
import { FaUsers, FaLightbulb, FaHandshake, FaChartLine } from 'react-icons/fa';
import { useLanguage } from '../context/LanguageContext';
import { preloadImages } from '../utils/imageLoader';

const StatCard = ({ icon, number, text }) => (
  <motion.div 
    className="stat-card"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    whileHover={{ scale: 1.05 }}
  >
    {icon}
    <h3>{number}</h3>
    <p>{text}</p>
  </motion.div>
);

const About = () => {
  const { language, translations } = useLanguage();
  const t = translations[language].about;

  const { scrollYProgress } = useScroll();
  
  const y = useTransform(scrollYProgress, [0, 1], [0, 200]);
  const opacity = useTransform(scrollYProgress, [0, 0.5, 1], [1, 0.5, 0]);

  // Preload images when component mounts
  useEffect(() => {
    const imagesToPreload = [
      images.about
    ];
    
    preloadImages(imagesToPreload).then(() => {
      // Images are preloaded
      console.log('All images preloaded successfully');
    });
  }, []);

  return (
    <div className="about-page">
      {/* Hero Section */}
      <section className="about-hero">
        <motion.div 
          className="about-hero-bg"
          style={{ y, opacity }}
        >
          <img src={images.about} alt="About Us" className="hero-image" />
          <div className="overlay"></div>
        </motion.div>
        <motion.div 
          className="about-hero-content"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="hero-title">{t.title}</h1>
          <p className="hero-subtitle">{t.subtitle}</p>
        </motion.div>
      </section>

      {/* Who We Are Section */}
      <section className="about-section about-who-we-are">
        <div className="container">
          <motion.div 
            className="content-wrapper"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <h2 className="section-title">{t.whoWeAre.title}</h2>
            <p className="section-content">{t.whoWeAre.content}</p>
          </motion.div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="about-section about-stats">
        <div className="container">
          <motion.div 
            className="stats-grid"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <StatCard 
              icon={<FaUsers className="stat-icon" />}
              number="15+"
              text={t.stats.experience}
            />
            <StatCard 
              icon={<FaLightbulb className="stat-icon" />}
              number="200+"
              text={t.stats.projects}
            />
            <StatCard 
              icon={<FaHandshake className="stat-icon" />}
              number="150+"
              text={t.stats.clients}
            />
            <StatCard 
              icon={<FaChartLine className="stat-icon" />}
              number="98%"
              text={t.stats.success}
            />
          </motion.div>
        </div>
      </section>

      {/* International Cooperation Section */}
      <section className="about-section about-cooperation">
        <div className="container">
          <motion.div 
            className="cooperation-content"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <h2 className="section-title">{t.cooperation.title}</h2>
            <div className="cooperation-text">
              <p>{t.cooperation.content}</p>
              <p>{t.cooperation.content2}</p>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default About;