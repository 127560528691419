import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../styles/components.css';
import ProjectPopup from '../components/ProjectPopup';

const FeaturedProject = ({ project, index, onProjectClick }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  
  // Preload the first image when component mounts
  useEffect(() => {
    const img = new Image();
    img.src = project.image[0];
    img.onload = () => setIsImageLoaded(true);
  }, [project.image]);

  return (
    <section className="featured-project">
      <div className="featured-background">
        {!isImageLoaded && (
          <div className="image-placeholder">
            <div className="loading-spinner"></div>
          </div>
        )}
        <motion.img 
          src={project.image[0]} 
          alt={project.name}
          initial={{ opacity: 0 }}
          animate={{ opacity: isImageLoaded ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        />
        <div className="featured-overlay"></div>
      </div>
      
      <div className={`featured-content ${index % 2 === 0 ? 'right' : 'left'}`}>
        <div className="featured-info">
          <span className="featured-number">.{String(index + 1).padStart(2, '0')}</span>
          <h2>{project.name}</h2>
          <p className="featured-location">{project.location}</p>
          <p className="featured-description">{project.description}</p>
          <div className="featured-meta">
            <div>
              <strong>Client:</strong>
              <p>{project.client}</p>
            </div>
            <div>
              <strong>Status:</strong>
              <p>{project.status}</p>
            </div>
          </div>
          <button 
            className="view-project"
            onClick={() => onProjectClick(project)}
          >
            VIEW PROJECT
            <span className="arrow">→</span>
          </button>
        </div>
      </div>
    </section>
  );
};

const FeaturedProjects = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  
  const featuredProjects = [
    {
      id: 1,
      name: "EVD building - Thanh Tri, Ha Noi",
      location: "Thanh Tri, Ha Noi",
      description: "A prestigious public building project showcasing modern architectural design with sustainable features.",
      client: "EVD Corporation",
      status: "Completed",
      year: "2008",
      type: "Public Building",
      image: [
        'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?w=1920&h=1080',
        'https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?w=1920&h=1080',
        'https://images.unsplash.com/photo-1600607687644-aac4c3eac7f4?w=1920&h=1080'
      ]
    },
    {
      id: 2,
      name: "Hotel, Office building – Phu Ly, Ha Nam",
      location: "Phu Ly, Ha Nam",
      description: "A mixed-use development combining luxury hospitality with premium office spaces.",
      client: "Ha Nam Development",
      status: "Completed",
      year: "2009",
      type: "Public Building",
      image: [
        'https://images.unsplash.com/photo-1582719508461-905c673771fd?w=1920&h=1080',
        'https://images.unsplash.com/photo-1545324418-cc1a3fa10c00?w=1920&h=1080',
        'https://images.unsplash.com/photo-1562778612-e1e0cda9915c?w=1920&h=1080'
      ]
    },
    {
      id: 3,
      name: "Techcombank Kim Thanh building – Lao Cai",
      location: "Lao Cai",
      description: "A modern banking facility that combines functionality with architectural excellence.",
      client: "Techcombank",
      status: "Completed",
      year: "2009",
      type: "Public Building",
      image: [
        'https://images.unsplash.com/photo-1577760258779-e787a1733016?w=1920&h=1080',
        'https://images.unsplash.com/photo-1497366811353-6870744d04b2?w=1920&h=1080',
        'https://images.unsplash.com/photo-1498496294664-d9372eb521f3?w=1920&h=1080'
      ]
    },
    {
      id: 4,
      name: "Viettel 380 Lac Long Quan building",
      location: "Tay Ho, Ha Noi",
      description: "A state-of-the-art office complex showcasing contemporary design and technological integration.",
      client: "Viettel Group",
      status: "Completed",
      year: "2010",
      type: "Public Building",
      image: [
        'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?w=1920&h=1080',
        'https://images.unsplash.com/photo-1481253127861-534498168948?w=1920&h=1080',
        'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?w=1920&h=1080'
      ]
    },
    {
      id: 5,
      name: "Radio the Voice of Vietnam building",
      location: "Ba Trieu, Hoan Kiem, Ha Noi",
      description: "A landmark broadcasting facility combining media functionality with architectural innovation.",
      client: "Voice of Vietnam",
      status: "Completed",
      year: "2010",
      type: "Public Building",
      image: [
        'https://images.unsplash.com/photo-1498496294664-d9372eb521f3?w=1920&h=1080',
        'https://images.unsplash.com/photo-1577760258779-e787a1733016?w=1920&h=1080',
        'https://images.unsplash.com/photo-1497366754035-f200968a6e72?w=1920&h=1080'
      ]
    }
  ];

  return (
    <>
      <div className="featured-projects">
        {featuredProjects.map((project, index) => (
          <FeaturedProject 
            key={project.id} 
            project={project} 
            index={index}
            onProjectClick={setSelectedProject}
          />
        ))}
        <div className="view-all-projects">
          <Link to="/projects">
            <motion.button
              className="btn btn-primary"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              View All Projects
            </motion.button>
          </Link>
        </div>
      </div>

      <AnimatePresence>
        {selectedProject && (
          <ProjectPopup 
            project={selectedProject} 
            onClose={() => setSelectedProject(null)} 
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default FeaturedProjects;