import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './pages/Main';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import FeaturedProjects from './pages/FeaturedProjects';
import { LanguageProvider } from './context/LanguageContext';
import './styles/App.css';

// Wrapper component to handle location changes
const AppContent = () => {
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('ABS Architecture Portfolio');
  const [pageDescription, setPageDescription] = useState('ABS Architecture offers professional architectural design and construction solutions.');

  useEffect(() => {
    // Only hide header/footer on home page during initial load
    setShowHeaderFooter(location.pathname !== '/' || sessionStorage.getItem('visited'));

    // Update meta tags based on current route
    switch (location.pathname) {
      case '/':
        setPageTitle('ABS Architecture Portfolio | Home');
        setPageDescription('Welcome to ABS Architecture - Vietnam\'s leading architectural design and construction solutions provider.');
        break;
      case '/about':
        setPageTitle('About Us | ABS Architecture Portfolio');
        setPageDescription('Learn about ABS Architecture\'s vision, mission, and our commitment to architectural excellence.');
        break;
      case '/projects':
        setPageTitle('Our Projects | ABS Architecture Portfolio');
        setPageDescription('Explore our diverse portfolio of architectural projects including public buildings, residential houses, and interior design.');
        break;
      case '/featured':
        setPageTitle('Featured Projects | ABS Architecture Portfolio');
        setPageDescription('Discover our most notable architectural projects and achievements.');
        break;
      case '/contact':
        setPageTitle('Contact Us | ABS Architecture Portfolio');
        setPageDescription('Get in touch with ABS Architecture for your architectural design and construction needs.');
        break;
      default:
        setPageTitle('ABS Architecture Portfolio');
        setPageDescription('ABS Architecture offers professional architectural design and construction solutions.');
    }
  }, [location]);

  const handleMainAnimationComplete = () => {
    setShowHeaderFooter(true);
    sessionStorage.setItem('visited', 'true');
  };

  return (
    <div className="app-container">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
      </Helmet>
      {showHeaderFooter && <Header />}
      <Routes>
        <Route path="/" element={<Main onAnimationComplete={handleMainAnimationComplete} />} />
        <Route path="/featured" element={<FeaturedProjects />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      {showHeaderFooter && <Footer />}
    </div>
  );
};

const App = () => {
  return (
    <HelmetProvider>
      <LanguageProvider>
        <Router>
          <AppContent />
        </Router>
      </LanguageProvider>
    </HelmetProvider>
  );
};

export default App;