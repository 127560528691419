import logo from '../assets/images/logo.png';

export const images = {
    logo: logo,
    hero: 'https://images.unsplash.com/photo-1487958449943-2429e8be8625?w=1920&h=1080',
    about: 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?w=1200&h=800',
    vision: 'https://images.unsplash.com/photo-1497366811353-6870744d04b2?w=1920&h=1080',
    experience: 'https://images.unsplash.com/photo-1481253127861-534498168948?w=1920&h=1080',
    innovation: 'https://images.unsplash.com/photo-1497366754035-f200968a6e72?w=1920&h=1080',
    cooperation: 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?w=1920&h=1080'
};