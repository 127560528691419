import React from 'react';
import { FaGlobe } from 'react-icons/fa';
import { motion } from 'framer-motion';

const LanguageToggle = ({ currentLang, onToggle }) => {
  return (
    <motion.button
      className="language-toggle"
      onClick={onToggle}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <FaGlobe className="globe-icon" />
      <span>{currentLang === 'en' ? 'EN' : 'VN'}</span>
    </motion.button>
  );
};

export default LanguageToggle; 