import React, { useState } from 'react';
import '../styles/components.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("loading");
    try{
     const response = await fetch('http://localhost:3001/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });

      if(response.ok) {
          setStatus('success');
          setName('');
          setEmail('');
          setMessage('');
      }else{
          setStatus('error');
          console.error('Failed to send email')
      }
    }catch(error){
        setStatus('error');
      console.error('Error sending email:', error);
    }

  };

  return (
    <div className="container py-5 contact-container">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">Name</label>
          <input type="text" className="form-control" id="name" required value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input type="email" className="form-control" id="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="mb-3">
          <label htmlFor="message" className="form-label">Message</label>
          <textarea className="form-control" id="message" rows="5" required value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
       {status === 'loading' && <p>Loading...</p>}
        {status === 'success' && <p>Message sent successfully!</p>}
       {status === 'error' && <p>Error sending message. Please try again.</p>}

      </form>
    </div>
  );
};

export default Contact;