import React from 'react';
import { NavLink } from 'react-router-dom';
import { companyInfo } from '../constants/text';
import { FaMapMarkerAlt, FaPhone, FaFax, FaEnvelope, FaGlobe } from 'react-icons/fa';
import { BsBank2 } from 'react-icons/bs';
import { HiDocumentText } from 'react-icons/hi';
import '../styles/components.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content container">
        <div className="footer-section">
          <h3>About ABS</h3>
          <p className="footer-about">
            Architecture and high Building Solutions Corporation (ABS) is one of prestigious companies 
            gradually building up reputation in architecture and construction in Vietnam.
          </p>
        </div>

        <div className="footer-section">
          <h3>Contact Info</h3>
          <ul className="footer-contact">
            <li>
              <FaMapMarkerAlt className="footer-icon" />
              <span>{companyInfo.address}</span>
            </li>
            <li>
              <FaPhone className="footer-icon" />
              <span>{companyInfo.tel}</span>
            </li>
            <li>
              <FaFax className="footer-icon" />
              <span>{companyInfo.fax}</span>
            </li>
            <li>
              <FaEnvelope className="footer-icon" />
              <a href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a>
            </li>
            <li>
              <FaGlobe className="footer-icon" />
              <a href={`http://${companyInfo.website}`} target="_blank" rel="noopener noreferrer">
                {companyInfo.website}
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <h3>Business Info</h3>
          <ul className="footer-business">
            <li>
              <BsBank2 className="footer-icon" />
              <div>
                <strong>Bank Account:</strong>
                <p>{companyInfo.bankAccount}</p>
              </div>
            </li>
            <li>
              <HiDocumentText className="footer-icon" />
              <div>
                <strong>Business Registration:</strong>
                <p>{companyInfo.businessReg}</p>
              </div>
            </li>
            <li>
              <HiDocumentText className="footer-icon" />
              <div>
                <strong>Tax Code:</strong>
                <p>{companyInfo.taxCode}</p>
              </div>
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul className="footer-links">
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink to="/projects">Projects</NavLink></li>
            <li><NavLink to="/about">About</NavLink></li>
            <li><NavLink to="/contact">Contact</NavLink></li>
          </ul>
        </div>
      </div>
      
      <div className="footer-bottom">
        <div className="container">
          <p>&copy; {currentYear} {companyInfo.name}. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;