import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useLanguage } from '../context/LanguageContext';
import LanguageToggle from './LanguageToggle';
import { FaBars, FaTimes } from 'react-icons/fa';
import { images } from '../constants/images';
import '../styles/components.css';

const Header = () => {
  const location = useLocation();
  const { language, toggleLanguage } = useLanguage();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <header className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <Link className="navbar-brand logo-container" to="/">
          <img src={images.logo} alt="ABS Logo" className="header-logo" />
        </Link>
        
        <button 
          className="navbar-toggler" 
          type="button" 
          onClick={toggleSidebar}
          aria-label="Toggle navigation"
        >
          <FaBars />
        </button>

        <div className={`navbar-collapse desktop-menu`}>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/projects">Projects</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about">About</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact">Contact</NavLink>
            </li>
          </ul>
          <LanguageToggle 
            currentLang={language} 
            onToggle={toggleLanguage} 
          />
        </div>

        {/* Mobile Sidebar */}
        <AnimatePresence>
          {isSidebarOpen && (
            <>
              <motion.div 
                className="sidebar-overlay"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={toggleSidebar}
              />
              <motion.div
                className="sidebar"
                initial={{ x: '100%' }}
                animate={{ x: 0 }}
                exit={{ x: '100%' }}
                transition={{ type: 'tween' }}
              >
                <button className="close-sidebar" onClick={toggleSidebar}>
                  <FaTimes />
                </button>
                <ul className="sidebar-nav">
                  <li>
                    <NavLink to="/" onClick={toggleSidebar}>Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/projects" onClick={toggleSidebar}>Projects</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about" onClick={toggleSidebar}>About</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact" onClick={toggleSidebar}>Contact</NavLink>
                  </li>
                </ul>
                <div className="sidebar-language">
                  <LanguageToggle 
                    currentLang={language} 
                    onToggle={toggleLanguage} 
                  />
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </header>
  );
};

export default Header;